import React,{useState} from 'react';
import './mycourses.css';
import {Link} from 'react-router-dom';
import reactimage from '../images/react.png'

const Courses=()=>{
 const allcourseDetails = [
  {
    clink:"/courses/practical-graphql-course",
    imglink:"https://i.ytimg.com/vi/YQPoUfjIgy4/maxresdefault.jpg",
    title:"GraphQL with React & Node js - Real Time Private Chat App",
    author:"Mukesh Phulwani",
    lan:"English",
    lessons:"40"
  },
  {
    clink:"/courses/Ecommerce-react-graphql",
    imglink:"https://i.ytimg.com/vi/prinwlqtzDk/maxresdefault.jpg",
    title:"React & GraphQL Ecommerce Shop",
    author:"Mukesh Phulwani",
    lan:"Hindi",
    lessons:"3"
  },
  {
    clink:"/courses/GraphQL",
    imglink:"https://i.ytimg.com/vi/NNNcoWZ6Ih0/maxresdefault.jpg",
    title:"GraphQL with React",
    author:"Mukesh Phulwani",
    lan:"Hindi",
    lessons:"19"
  },
  {
    clink:"/courses/Flutter-Course",
    imglink:"https://i.ytimg.com/vi/hZPis2IrPJw/maxresdefault.jpg",
    title:"Flutter 2 Course",
    author:"Mukesh Phulwani",
    lan:"Hindi",
    lessons:"29"
  },
  {
    clink:"/courses/Firebase-web",
    imglink:"https://i.ytimg.com/vi/EtRCneCCqU4/maxresdefault.jpg",
    title:"Firebase for web",
    author:"Mukesh Phulwani",
    lan:"Hindi",
    lessons:"23"
  },
  {
    clink:"/courses/Redux-toolkit",
    imglink:"https://i.ytimg.com/vi/LZCZtFem74o/maxresdefault.jpg",
    title:"Redux Toolkit Mastery",
    author:"Mukesh Phulwani",
    lan:"Hindi",
    lessons:"23"
  },
  {
    clink:"/courses/Ecommerce-Nextjs",
    imglink:"https://i.ytimg.com/vi/n47Y5-hSFSs/maxresdefault.jpg",
    title:"Ecommerce using Next js",
    author:"Mukesh Phulwani",
    lan:"Hindi",
    lessons:"23"
  },
  {
    clink:"/courses/React-native-firebase",
    imglink:"https://i.ytimg.com/vi/ntPQ-IPm3AM/maxresdefault.jpg",
    title:"React Native & Firebase [2021]",
    author:"Mukesh Phulwani",
    lan:"Hindi",
    lessons:"17"
   
  },
  { 
    clink:"/courses/React-Nextjs-Firebase",
    imglink:"https://i.ytimg.com/vi/m9tPoGa_8p8/maxresdefault.jpg",
    title:"React + Next js + Firebase",
    author:"Mukesh Phulwani",
    lan:"Hindi",
    lessons:"6"
   
  },
  {
    clink:"/courses/Django-course",
    imglink:"https://i.ytimg.com/vi/K_Lz_7CMb7Q/maxresdefault.jpg",
    title:"Django 3 Course",
    author:"Mukesh Phulwani",
    lan:"Hindi",
    lessons:"26"
  },
  {
    clink:"/courses/Data-Science",
    imglink:"https://i.ytimg.com/vi/-yN78ufLJk8/maxresdefault.jpg",
    title:"Data Science & ML",
    author:"Mukesh Phulwani",
    lan:"Hindi",
    lessons:"25"
  },
  {
    clink:"/courses/MERN-stack-Instagram-clone",
    imglink:"https://i.ytimg.com/vi/8BqA5DQp0j4/maxresdefault.jpg",
    title:"MERN STACK - Instagram clone",
    author:"Mukesh Phulwani",
    lan:"English",
    lessons:"51"
  },
  {
    clink:"/courses/React-Native",
    imglink:"https://i.ytimg.com/vi/5XXI6OwP4cI/maxresdefault.jpg",
    title:"React Native & Redux course",
    author:"Mukesh Phulwani",
    lan:"English",
    lessons:"64"
  },
  {
    clink:"/courses/competitive-coding-python",
    imglink:"https://i.ytimg.com/vi/iD_8AbPKKrw/maxresdefault.jpg",
    title:"Competitive Coding using Python",
    author:"Mukesh Phulwani",
    lan:"Hindi",
    lessons:"18"
  },
  {
    clink:"/courses/React-js",
    imglink:reactimage,
    title:"React js master Course",
    author:"Mukesh Phulwani",
    lan:"Hindi",
    lessons:"45",

  }
]
 const  [courseDetails,updateCourseDetails] = useState(allcourseDetails)

   const filterCourse = (lang)=>{
     updateCourseDetails(allcourseDetails.filter(cou => cou.lan != lang))
   }

  return (
    <>
     <div className="mychip">
        <div className={courseDetails.length == allcourseDetails.length ?"chip pink white-text":"chip"} onClick={()=>filterCourse('none')}>
          All
        </div>
        <div className={courseDetails.length == 3 ?"chip pink white-text":"chip"} onClick={()=>filterCourse('Hindi')}>
          English
        </div>
        <div className={courseDetails.length == 12 ?"chip pink white-text":"chip"}  onClick={()=>filterCourse('English')}>
        Hindi
        </div>
      </div>
     <div className="#212121 grey darken-4 " style={{padding:"5%",display:"flex",flexWrap:"wrap",justifyContent:"start"}}>
             
             {
              courseDetails.map((item)=>{
                 return (
                   <Link className="course-card" to={item.clink}  key={item.title} >
                   
                         <div className="card" 
                        //  style={{width:"20%"}}
                         >
                          <div className="card-image">
                            <img src={item.imglink}/>
                         
                          </div>
                          <div className="card-content black-text">
                          <span className="card-title">{item.title}</span>
                            <p> Created by {item.author} <br />
                               <i className="tiny material-icons">library_books</i> {item.lessons} Lesson <br />
                             Language - {item.lan}</p>
                          </div>
                          <div className="card-action" style={{color:"#6200ea"}}>
                            Start Course
                          </div>
                        </div>
                      
                   
                      
                    </Link> 
                 )
               })
             }
                        
     </div>
     </>
  )
}

export default Courses ;