import React from 'react';

const About=()=>{
  return (
    <div className="#6200ea deep-purple accent-4">
    <div  style={{borderBottomRightRadius:"29% 11%",padding:"20px",backgroundColor:"#ebebeb"}}>
    <div className="container con1" style={{fontSize:"19px"}}>
      <div className="row">
            <div className="col s12 l6" >
                <div style={{color:"#6200ea",textAlign:"center"}}>
                    <img className="circle responsive-img" src={require('../images/finalbg.png')} alt="" style={{filter:"drop-shadow(0px 0px 1px black)"}} />
                  
                     <h4 className="center-align">Mukesh Phulwani</h4>
                     <h5 className="center-align">Creator of Coders Never Quit</h5>    
                  
                    
                </div>
              
            </div>
            <div className="col s12 l6">
              Coders never quit is a platform to learn programming for free !!
                <h4 style={{color:"#6200ea"}}>Why us ?</h4>
                <p>we see lot of students faces difficulties in learning programming. They dont find a proper path for getting started with programming and
                    they end up with investing heavy amount of money in online and offline courses even if they cant afford it.
                    We believe that learning should be free,easy and exciting that is the main reason behind starting CODERS NEVER QUIT.
                    We will help you to become a true creator,innovator and problem solver !!
                </p>
                <p style={{color:"#6200ea"}}>Hope you had fun reading about the platform!</p>
            </div>
      </div>
      <div>
        <p style={{color:"#6200ea"}}>A Little About Me...</p>
        <p>Hey everyone,my name is mukesh phulwani and I have been coding from a long time, now I share my knowledge gained during that time on my platform 
          CODERS NEVER QUIT. I work as a fullstack developer and an online instructor.
        </p>
        <p>
         My specialities mainly gravitate around one central language that is JavaScript and after spending some time with JS,
         I realised that there is no turning back.Feel free to catch me up on my social media accounts mentioned below.

        </p>
      </div>
        
    </div>
   </div>  
   </div>
  )
}

export default About ;