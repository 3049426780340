import React from 'react';
import Mybackground from  '../components/Background';
import Youtube from '../components/Youtube';
import Wave from '../components/Wave'

const Home=()=>{
  return (
   <div className="rootbg">
       <Mybackground />
       <div >
       <Wave />
       <div className="mera">
            <Youtube/>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 100" preserveAspectRatio="none" style={{display:"block"}}>
                <circle fill="#6200ea" cx="100" cy="100" r="100"></circle>
            </svg>
       </div>
       </div>
   </div>
  )
}

export default Home ;