import React,{useState,useEffect,useRef} from 'react';
import './mycourses.css';
import ReactPlayer from 'react-player';
import {Allcourse} from './data'
const CourseStructure = (props)=>{

    useEffect(()=>{
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    },[])

    

    // useEffect(()=>{

    //     fetch(`https://www.googleapis.com/youtube/v3/playlistItems?part=contentDetails,snippet&maxResults=50&playlistId=${"PLB97yPrFwo5hIt2tPCvuOjpuXfb7oXZNN"}&key=AIzaSyDuA5k_KmwoW1A5ZZyh0pj6AxuY-YLxNmU`)
    //     .then(res=>res.json())
    //     .then(data=>{
    //         console.log(data)
    //      const  result = data.items.map(item=>{
    //             // console.log(item.snippet.title)
    //             // console.log(item.contentDetails.videoId)
    //           return {tit:item.snippet.title,videid:item.contentDetails.videoId}
    //         })
    //         console.log(result)
    //     })

    // })

   const coursename = props.match.params.name;
   const course= Allcourse
   const liselected = useRef(null)
    // const [title,updateTitle] = useState(course[coursename].playlist[0].tit) 
    const [videid,updateVideid] = useState(course[coursename].playlist[0].videid) 
    const [desc] = useState(course[coursename].desc)
    const [counter,updateCounter] = useState(0) 

    useEffect(()=>{
      //scroll to selected list
      liselected.current.scrollIntoView({behavior: "smooth",block:"nearest"})
    },[counter])

    const watched = (vid)=>{
        if(localStorage.getItem("saveID")){
            if(JSON.parse(localStorage.getItem("saveID")).includes(vid)){
                return true
            }
        }
        return false
    }
    
    const ListView = ()=>{
        
        return(
                 <ul className="collection  myresponsive-list" style={{border:"0px"}}>
                    {
                        course[coursename].playlist.map((item,index)=>{
                            return (
                            
                                
                                <li key={item.videid} ref={index === counter ? liselected: null} style={{color:"black"}} className={index === counter ? "collection-item turn-blue":"collection-item"} onClick={(e)=>{
                                      
                                    // updateTitle(item.tit)
                                    updateVideid(item.videid)
                                    updateCounter(index)
    
                               }}>
                                   {item.tit} 
                                   {watched(item.videid)
                                    && <i className="tiny material-icons">check</i>
                                   }
                                  
                               </li>
                          
                            )
                        })
                    }
                </ul>
        )
    }
    const displayTileAndPlayer = ()=>{
    
        return (
              <>
                   
                     <div className="row video-pad">
                          {/* <div className="col l9 s12" style={{padding:"0px"}}>
                          
                           <div className="video-container">
                                  <ReactPlayer
                                        className='react-player'
                                        url={`https://www.youtube.com/watch?v=${videid}`}
                                        controls={true}
                                        // playing={true}
                                       onEnded={()=>{
                                             if(localStorage.getItem("saveID")){
                                                let data = JSON.parse(localStorage.getItem("saveID"))
                                                 localStorage.setItem("saveID",JSON.stringify([...data,videid]))
                                             }else{
                                               
                                                localStorage.setItem("saveID",JSON.stringify([videid]))
                                             }
                                           
                                       }}
                                        />
                                                        
                            </div>
                          
                        </div> 
                        <div className="col l3 s12" style={{margin:"0px",padding:"0px"}}>
                           {listView()}
                        </div> */}
                        <div style={{display:"flex",flexWrap:"wrap"}}>
                            <div className="video-container item1">
                                    <ReactPlayer
                                            className='react-player'
                                            url={`https://www.youtube.com/watch?v=${videid}`}
                                            controls={true}
                                            // playing={true}
                                            onEnded={()=>{
                                                if(localStorage.getItem("saveID")){
                                                    let data = JSON.parse(localStorage.getItem("saveID"))
                                                    localStorage.setItem("saveID",JSON.stringify([...data,videid]))
                                                }else{
                                                
                                                    localStorage.setItem("saveID",JSON.stringify([videid]))
                                                }
                                            
                                        }}
                                            />
                                                            
                                </div>
                                <div className="item2">
                         
                                    <ListView />
                                </div>
                        </div>
                     </div>
                     <p style={{fontSize:"18px",padding:"20px",color:"white"}}>{desc}</p> 
              </>
        )
    }
   

           
       return (
            <div className="#212121 grey darken-4">
                <div className="black-text" style={{padding:"1px 0px",backgroundColor:"#212121"}}>
                            {displayTileAndPlayer()}

                           
                </div> 
                <ins className="adsbygoogle"
                style={{display:"block"}}
                data-ad-client="ca-pub-8746049107420517"
                data-ad-slot="9588809083"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </div>
        )  
    
 
}

export default CourseStructure ;