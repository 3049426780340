export const Allcourse={
    "practical-graphql-course":{
        playlist:[
            {tit: '#1 Project Overview', videid: 'nMWQtVd4_NI'},
            {tit: '#2 What and why of GraphQL', videid: 'cu0i-2zFlAM'},
            {tit: '#3 GraphQL vs Rest API', videid: 'eYGwmy_Qodo'},
            {tit: '#4 GraphQL Terminology - Query, Mutation, Schema and Resolvers', videid: 'p5yKe9P65m0'},
            {tit: '#5 Setup Apollo server and Hello world', videid: 'BOTKcUONRrE'},
            {tit: '#6 Query, variables and Resolvers', videid: 'IVdgMvtSNfc'},
            {tit: '#7 Mutation and input type', videid: '5kd7Cpainvk'},
            {tit: '#8  Parent argument for relational data', videid: 'JZmmEb5-7Tk'},
            {tit: '#9 Context, Aliases and Fragment', videid: 'ZDw1eKl2vLQ'},
            {tit: '#10 Setup Prisma and User model', videid: 'RHqgaKYrNK8'},
            {tit: '#11 Signup User mutation and password hashing', videid: 'noQv0UuCNvY'},
            {tit: '#12 Sign In User mutation and Jwt sign', videid: '23WkzWiRKD4'},
            {tit: '#13 Understanding jwt and Authorization', videid: 'O81nQVcOznE'},
            {tit: '#14 Adding Authorization', videid: 'DN5RqkYtuaI'},
            {tit: '#15 Message Model Prisma', videid: 'RJBwGevnulo'},
            {tit: '#16 Send Message Mutation', videid: 'OMvOgjkCNwU'},
            {tit: '#17 Query to get messages between 2 users', videid: 'LBGCjHTBij0'},
            {tit: '#18 Create react app and install dependencies', videid: 'buPhl3qgDEc'},
            {tit: '#19 Sign Up page UI', videid: 'vWRUqokxOoE'},
            {tit: '#20 Login page UI', videid: 'qNwLgc1f99k'},
            {tit: '#21 Side Bar Component', videid: 'Jn9cR4mJCvc'},
            {tit: '#22 Adding Routing', videid: 'xHgYNKuQgMM'},
            {tit: '#23 Chat Screen UI', videid: 'Sz-IeLgh9Yc'},
            {tit: '#24 Typo and Logout icon', videid: 'Hau392b0RLU'},
            {tit: '#25 Why Apollo client, Making request using fetch', videid: 'EVCftgvO0Us'},
            {tit: '#26 Setup Apollo client with React', videid: 'c-KUEhGJOaY'},
            {tit: '#27 useMutation hook and Signup User', videid: '8bjA6lfB1u0'},
            {tit: '#28 Call Login mutation and logout', videid: '2cuGOXK0T_4'},
            {tit: '#29 useQuery & attach token in headers', videid: '1Lz4yOcVA8w'},
            {tit: '#30 Showing messages on UI', videid: 'aY11QM4A8Qs'},
            {tit: '#31 Sending text message from UI', videid: 'reptOohMwE4'},
            {tit: '#32 Setup graphql-ws with Apollo Server', videid: 'iLbyPLROb_k'},
            {tit: '#33 Subscription schema, resolver and PubSub asyncIterator', videid: 'Ug2RBIBl61I'},
            {tit: '#34 Testing Subscription in playground', videid: 'lEICgXIFub4'},
            {tit: '#35 Setup Subscription with apollo client', videid: 'PWzQSMbrEXE'},
            {tit: '#36 useSubscription hook for real time notification', videid: '3zCiAb5YvGU'},
            {tit: '#37 Inspecting web socket  request in network tab', videid: '2BoVT1CKLSM'},
            {tit: '#38 Fixing bug', videid: 'AvtbR7uA3Xk'},
            {tit: '#39 Deploy Apollo Server', videid: 'ItL_ACUdh8M'},
            {tit: '#40 Deploy Apollo Client (React App)', videid: 'E1-MsTU7PRA'}
        ],
        desc:"In this course we will be creating One to One private Chat app like MS teams, using latest technologies available on the internet that is 1)React 2)Apollo Client 3)Material UI v5 4)Apollo Server 5)Websockets"
    },
    "Ecommerce-react-graphql":{
        playlist:[
             {tit: 'Ecommerce using Apollo React strapi stripe', videid: 'prinwlqtzDk'},
             {tit:'Upload strapi v4 images to cloudinary and deploy strapi backend',videid:'F2rQPEeL9FU'},
             {tit:'Deploy React App (Apollo Client)',videid:'dVVANfAh5tk'}
        ],
        desc:"In this course we will be creating a Ecommerce shop using latest technologies available on the internet that is 1)React 2)Apollo Client 3)GraphQL 4)Strapi 5)stripe."
    },
    "GraphQL":{
        playlist:[
             {tit: '#0 Project Overview and Tech Stack', videid: 'hKLmoiYaBds'},
             {tit: 'Why GraphQL | GraphQL vs REST API', videid: 'NNNcoWZ6Ih0'},
             {tit: '#1 Hello World in GraphQL', videid: 'Fpf2qJE0Iag'},
             {tit: '#2 Query and Resolvers हिंदी में', videid: 'hUJwU-OqKxQ'},
             {tit: '#3 Query Variables in GraphQL', videid: 'MLNnKFxua8E'},
             {tit: '4 Mutation, Input Type and Aliases', videid: 'GieVHRrcR-U'},
             {tit: '#5 Add MongoDB and creating Model', videid: 'WyVwfUo18pI'},
             {tit: '#6 Signup Login Mutation and JWT', videid: '4_jkjrhGwbc'},
             {tit: '#7 Authorization and Create Quote', videid: '5Mak_g9mR4I'},
             {tit: '#8 Mongoose Queries हिंदी में', videid: 'wZTMBbTyBP4'},
             {tit: '#9 React UI for login,Signup,Home and Profile page', videid: 'ydtVdVFI81s'},
             {tit: '#10 Adding Routing using React Router v6', videid: 'TfCf4zIlZ4Y'},
             {tit: '#11 Apollo client and useQuery', videid: 'AjL6ljhKYNE'},
             {tit: '#12 usemutation and signup', videid: 'SqaIhnv3eXE'},
             {tit: '#13 login and navlink update', videid: 'kIJs0WTZh1A'},
             {tit: '#14 create quote & add token in header', videid: 'Mj-elp4kZqA'},
             {tit: '#15 my profile page', videid: '-Yqdyp0gS0M'},
             {tit: '#16 other user profile page', videid: '5s1PG9nlehU'},
             {tit: '#17 deploy and fixing issue in deployment', videid: 'A2sM0HLenxA'},
        ],
        desc:"In this course we will be learning GraphQL with Apollo server,Node js, MongoDB, React js and Apollo Client with Project"
    },
    "React-native-firebase":{
        playlist:[
             {tit: "React Native in one video for complete beginners", videid: "4Cc75EC1IlE"},
             {tit: "Part 1/2 | OLX Clone using React Native & Firebase", videid: "ntPQ-IPm3AM"},
             {tit: "Part 2/2 React Native Push Notifications using firebase cloud messaging ", videid: "tKZeFRB_xMk"},
             {tit: "selfie app in React native | access camera & flash", videid: "wGI34rYuZBA"},
             {tit: "#0 Project Overview | WhatsApp Clone", videid: "GvbvYknznWc"},
             {tit: "#1 Prerequisites | WhatsApp Clone", videid: "boCMBlsGJzw"},
             {tit: "#2 Installing dependencies", videid: "TAwUzGIP23A"},
             {tit: "#3 Multi Step signup screen | WhatsApp Clone", videid: "mBuFN9Ahet0"},
             {tit: "#4 Login UI and Navigation setup | WhatsApp Clone", videid: "S7i8Nlzg6LU"},
             {tit: "#5 Access and upload image to firebase | WhatsApp Clone", videid: "YM1lXOBi0WY"},
             {tit: "#6 Signup & login to Firebase | WhatsApp Clone", videid: "WsOCNkA8SpM"},
             {tit: "#7 Showing all User on Home Screen | WhatsApp Clone", videid: "g_nSRcjzivw"},
             {tit: "#8 Gifted chat UI & one to one chat using firestore", videid: "flAFZC-xNwk"},
             {tit: "#9 Real time chat and gifted chat customization", videid: "GsWp3KoMJs8"},
             {tit: "#10 Security Rules for chat App | WhatsApp Clone", videid: "q7xGvGAs1DQ"},
             {tit: "#11 Adding User Online & last seen status", videid: "Oa-4YUOqdLQ"},
             {tit: "#12 Profile Screen | WhatsApp Clone", videid: "p6QYb80n_wc"}
        ],
        desc:"In this course we will be making 2 projects 1) OLX clone - Users can sell their old items and 2) whatsApp clone - one to one chat Application."
    },
    "Redux-toolkit":{
        playlist:[
            {tit: "Redux Toolkit Master Course in Hindi", videid: "LZCZtFem74o"},
            {tit: "#1 configureStore & useSelector", videid: "xSMPUnrrhEQ"},
            {tit: "#2 useDispatch & createReducer", videid: "ewXczSMeS9w"},
            {tit: "#3 createAction in Redux Toolkit", videid: "TozshNv-C4U"},
            {tit: "#4 Async Action using Redux thunk", videid: "ksPAaZDxvwo"},
            {tit: "#5 createSlice", videid: "DyVz0nwd2SA"},
            {tit: "#6 createAsyncThunk", videid: "cdFHgDPGm4I"},
            {tit: "#7 init server & connecting to mongoDB", videid: "UQa0E2G2O2M"},
            {tit: "#8 Signup user API in Node js", videid: "hu9hm7cJ6Q0"},
            {tit: "#9 SignIn User API in Node js", videid: "rKp-YShq_tU"},
             {tit: "#10 middleware in node js to verify jwt", videid: "QvUTiueqFAI"},
             {tit: "#11 Create, Get and Delete todo Apis", videid: "m2p_qzefg98"},
             {tit: "#12 Signup user in React Redux Toolkit", videid: "G-oj6TrGSrI"},
             {tit: "#13 SignIn user in React Redux toolkit", videid: "yGoOzQGE3Ok"},
             {tit: "#14 Create, Get & Delete todo", videid: "fRgtKQgNQ44"},
             {tit: "#15 Logout user", videid: "wXkXONe3Myk"},
             {tit: "#16 Deploy MERN application on vercel", videid: "fTIWf1PpBl8"},
             {tit: "#17 Authentication Component in React Native", videid: "bFvY8au_OLw"},
             {tit: "#18 Todo Screen UI in React Native", videid: "LlXi2zb6Plc"},
             {tit: "#19 Adding Redux Toolkit in React Native & Authentication", videid: "IZLEyBea43w"},
             {tit: "#20 Create, Get, Delete Todo in React Native", videid: "CWzzx_piqxw"},
             {tit: "#21 Build APK using Expo & React Native", videid: "7y3kQfrrCQQ"},
             {tit: "#22 Redux Saga Crash Course", videid: "-RuLHMF-KFE"}
        ],
        desc:"In this course we will be learning about Redux Toolkit in depth we will create Todo app including authentication using React, React Native, Node js and MongoDB"
    },
    "Flutter-Course":{
        playlist:[
            {tit: "#0 Flutter Introduction and SDK installation in windows", videid: "hZPis2IrPJw"},
            {tit: "#1 Android Studio installation for Flutter", videid: "VPJjCkdK0EU"},
            {tit: "#2 How to use Android Studio as a IDE", videid: "jIgNDP5zb38"},
            {tit: "#3 Dart Crash Course for Flutter", videid: "UVsl8AHbxqo"},
            {tit: "#4 Setup Visual Studio Code for Flutter", videid: "NqsltarVOoU"},
            {tit: "#5 Hello World in Flutter", videid: "apTjGHq6tHA"},
            {tit: "#6 Scaffold, AppBar, FloatingActionButton", videid: "Xe4-XJPCtSQ"},
            {tit: "#7 Textstyle and FAB position", videid: "j4iOQRUhiUQ"},
            {tit: "#8 Statefull widget", videid: "btKJjP0mhMU"},
            {tit: "#9 jokes app", videid: "Dz3Ighg1jKc"},
            {tit: "#10 container and box decoration", videid: "mskNOkyTMuE"},
            {tit: "#11 final vs const and buttons", videid: "WBqSHkyCYiw"},
             {tit: "#12 Row and column", videid: "k4NWkBAOl6c"},
             {tit: "#13 Love calculator and null safety migration", videid: "mTKQkBtFSmo"},
             {tit: "#14 InputDecoration and circularprogressindicator", videid: "hnEyQEcy4x0"},
             {tit: "#15 Adding validation in textfield", videid: "XB7nM70X7dM"},
             {tit: "#16 Theme", videid: "vur8n5B8iWM"},
             {tit: "#17 Widget lifecycle", videid: "vxDV8r_wDZM"},
             {tit: "#18 Deploy flutter web app to github pages", videid: "Lm2BIggeGwk"},
             {tit: "#19 single child scroll view and ListView", videid: "q3PthjpydL0"},
             {tit: "#20 ListView builder", videid: "QDrEUHfpKB0"},
             {tit: "#21 Add images in flutter wallpaper App part 1", videid: "sV6ujAUkVyo"},
             {tit: "#22 AlertDialog Wallpaper App part 2", videid: "LNpVBT-4zVk"},
             {tit: "#23 Set Image as home screen | Wallpaper App part 3", videid: "BoDCdTUdwpo"},
             {tit: "#24 Navigator and Passing data to other screen", videid: "vXYCsiUSb9A"},
             {tit: "#25 Navigate with named routes & Pass arguments", videid: "EacIWhFwlu0"},
             {tit: "#26 Contact App in flutter", videid: "bgg2EVLOZlo"},
             {tit: "#27 Future then/catch async/await", videid: "LWIvRcW5ld8"},
             {tit: "#28 Contact App Dynamic data", videid: "TM9GrDEKrQw"}
        ],
        desc:"In this course we will be learning about Flutter plus we will create 4 projects 1) Jokes App 2) Love calculator 3) Wallpaper App 4) Contact App"
    },
    "competitive-coding-python":{
        playlist:[
        {tit: "Competitive Programming using Python in Hindi", videid: "iD_8AbPKKrw"},
        {tit: "Time Complexity and Big O Notation", videid: "XIuHmwHxp4I"},
        {tit: "Solving Arithmetic Problems in O(1)", videid: "fjAzWGjIqeo"},
        {tit: "Sieve of Eratosthenes Algorithm for prime number in python", videid: "ssuIdGXIAic"},
        {tit: "Sexy primes problem solution in Python", videid: "L0BqVQD_cks"},
        {tit: "Recursion Mastery | Understand how Recursion works", videid: "9uHzwKyn2KU"},
        {tit: "Find Permutation of String using recursion in Python", videid: "wvf4ValChy4"},
        {tit: "Memoization and Dynamic programming in Hindi", videid: "NEZWpksHgFM"},
        {tit: "#1 Length of Longest common subsequence LCS using recursion", videid: "s2xXC7TNuqo"},
        {tit: "#2 Length of Longest common subsequence LCS using Tabulation", videid: "fuwzsqpEPvA"},
        {tit: "Minimum Path Sum or Cost", videid: "qASaHbcS07g"},
        {tit: "weighted maze challenge", videid: "k82-JehAQD8"},
        {tit: "Binary Search implementation using Python", videid: "qBJwFyTqVWA"},
        {tit: "Peak index in mountain array leetCode", videid: "m0h73AUjZAQ"},
        {tit: "Combinations in Python without using itertools", videid: "FDOqqSKPSYo"},
        {tit: "Solving combination based problem", videid: "RrHt1UgbO-k"},
        {tit: "Sum of subset problem using Dynamic programming", videid: "o82qrKUxUww"},
        {tit: "Count frequencies of element in list", videid: "_hrCQfxAtEY"}
        ],
        desc:"In this course we will start our journey in cometitive coding using Python"

        
    },
    "Firebase-web":{
        playlist:[
             {tit: "#0 Introduction to firebase | Project Overview", videid: "EtRCneCCqU4"},
             {tit: "#1 HTML Template Setup", videid: "Uy0Gw1YxvPo"},
             {tit: "#2 Creating new project in firebase and Adding SDK in our web application", videid: "GnNtxG7OS78"},
             {tit: "#3 How Authentication Works in Firebase", videid: "sm_Vs5GQn4g"},
             {tit: "#4 Signup User or Create new User with email and Password", videid: "t2UdPnZdBVA"},
             {tit: "#5 Login or Sign In user with email and password", videid: "3Xi3oxn5VkQ"},
             {tit: "#6 logout and get currently signed in user Info", videid: "Y-sNJySzOtw"},
             {tit: "#7 Login with Google Firebase Authentication", videid: "WH9PyMoNNIc"},
             {tit: "#8 Email Verification in Firebase", videid: "ctelhmjYjE4"},
             {tit: "#9 Cloud Firestore No SQL DB Introduction", videid: "ZDLoeOaMdqA"},
             {tit: "#10 Firestore queries basics", videid: "eAVUGNCbE_M"},
             {tit: "#11 Firebase Firestore Security rules", videid: "XrAlKio1H24"},
             {tit: "#12 Create User Collection in Firestore", videid: "HAPv4qswwSM"},
             {tit: "#13 Show user information on frontend", videid: "QUf0BJoDFGo"},
             {tit: "#14 Listen for change in Realtime using onSnapshot", videid: "fBPpwnqWsPg"},
             {tit: "#15 Update user profile details", videid: "7yGyVa_SWKg"},
             {tit: "#16 Cloud Storage introduction and Security rules", videid: "E_XG2l6MZfo"},
             {tit: "#17 Upload Images to Firebase Cloud storage", videid: "QQg5dBoDs1E"},
             {tit: "#18 Show Profile image on web page", videid: "cV5JXoUeRyQ"},
             {tit: "#19 get all docs from users collection", videid: "_2Z2_ZTg_S4"},
             {tit: "#20 Firebase Hosting tutorial", videid: "WbWAgRkTRcU"},
             {tit: "#21 Restrict Firebase API key usage requests to the specified websites", videid: "-GUP_40iz6c"},
             {tit: "#22 Solving some issues and wrap up", videid: "QPRR-Xvqd4M"},
        ],
        desc:"In this course we will learn firebase for web from scratch plus we will create a job portal application using javascript"  
    },
    "React-Nextjs-Firebase":{
        playlist:[
             {tit: "#0 Todo App overview", videid: "7kjSGUpzg_Q"},
             {tit: "#1 Creating UI for login,signup page and adding Routing", videid: "DCz6tQdldmY"},
             {tit: "#2 Adding Email and Password authentication", videid: "nSAXXmQyz4s"},
             {tit: "#3 Create Read Delete Update in React And Firestore", videid: "-7Oj4V9jOxo"},
             {tit: "#4 Hosting React & firebase app on firebase hosting", videid: "1q5cmo6zqww"},
            {tit: "Next js with Firebase course | Blog app", videid: "m9tPoGa_8p8"}
        ],
        desc:"In this course we will be building 2 projects 1) Todo app 2) Full Blog app using Next js"  
    },
    "React-js":{
        playlist:[
                {tit: "#0 - Introduction to React js | why React.js | React js tutorials in hindi", videid: "edblCnJhvdI"},
                {tit: "#1 - React js basics | jsx | props | state | component | React js tutorials in hindi", videid: "MXZvQuG5mQc"},
                {tit: "#2 - React Router v4 | react router dom | BrowserRouter Route Link NavLink |React js in hindi", videid: "VPIrZr8YVrQ"},
                {tit: "#3 - connecting react js with nodejs (express) backend | MERN stack tutorial in hindi", videid: "ngwZT1FR5Fw"},
                {tit: "#4 - Displaying server response on our react js front-end | MERN stack tutorial in hindi", videid: "goOuCIv39e4"},
                {tit: "#5 - fetching data from nodejs (express) server and displaying on dom | MERN STACK tutorial in hindi", videid: "r2L8ffwx9TY"},
                {tit: "#6 - deleting data from nodejs (express) server and upading the dom | MERN STACK tutorial in hindi", videid: "Scoqcqtv4rs"},
                {tit: "#7 - changes to be done on production level | react js | MERN stack tutorials in hindi", videid: "ruKOdubLZ30"},
                {tit: "#8 - introduction to redux | why redux | what is redux | react and redux tutorial in hindi", videid: "ahZPLgj1tnY"},
                {tit: "Redux with react js basics | store | dispatch action | reducers | react and redux tutorials in hindi", videid: "_lS7bXk1xH4"},
                {tit: "Action creators and redux thunk for asynchronous tasks in redux | react and redux tutorials in hindi", videid: "pSzY5kh9MCs"},
                {tit: "combine one or more reducers in redux | CombineReducer | react and redux tutorials in hindi", videid: "jyp0pR7fpBg"},
                {tit: "Adding redux in our MERN stack project | react and redux tutorials in hindi", videid: "TUZn36FU_ZU"},
                {tit: "fetching data from server with redux included | MERN stack | react and redux tutorials in hindi", videid: "hM0KXfmFzCg"},
                {tit: "posting and delete from server with redux included | MERN stack | react and redux tutorials in hindi", videid: "XngdVeRpBh0"},
                {tit: "Route parameters or params | react router part 2 | react js tutorials in hindi", videid: "JqtGzCIczsk"},
                {tit: "Higher order components or hoc in react js  | react js tutorial in hindi", videid: "evYaO41alI4"},
                {tit: "React Component Lifecycle methods or Lifecycle hooks | react js tutorial in hindi", videid: "S2vlwqyr4c8"},
                {tit: "useState Hook in react js | React hooks tutorial | react tutorial js in hindi", videid: "_GQvquNxKOI"},
                {tit: "useEffect Hook in react js | React hooks tutorial | react tutorial js in hindi", videid: "OJ5BkLBxD6Y"},
                {tit: "jokes fetching app using react hooks | react js tutorials in hindi", videid: "U-BCn_c5bW4"},
                {tit: "refs and useRef hook in react js | react hooks tutorial | react js in hindi", videid: "OamaAmhtYl4"},
                {tit: "React Fragments | react js  tutorials for beginners in hindi", videid: "q6eHzUw1aqI"},
                {tit: "context in react js | context api | react js tutorials in hindi", videid: "St9j_NruDK4"},
                {tit: "useContext hook and multiple context in react js | react hook tutorial in hindi", videid: "CiqvvCHKRXI"},
                {tit: "useReducer hook in react js | react js tutorials in hindi", videid: "pY0ZWFzXfW0"},
                {tit: "context with useContext + useReducer for state management | react js tutorials in hindi", videid: "jr-bh0VYlE8"},
                {tit: "Redux Form Tutorials | why redux form ? | redux tutorials in hindi", videid: "9p01hMxQCUI"},
                {tit: "#0 - project overview | oauth using react js | redux | node js | passport js | mongodb", videid: "gtg1-N7yfGM"},
                {tit: "#1 - setting up passport and google OAuth 2.0 strategy in node js | oauth mern stack in hindi", videid: "e8zrIq9NlUo"},
                {tit: "#2 - saving user to mongodb database (mongodb atlas) | oauth mern stack tutorial in hindi", videid: "F9t0ZDcnb6g"},
                {tit: "#3 - serialize and deserialize user to and from the session | oauth using mern stack in hindi", videid: "PpHYuAmUC0E"},
                {tit: "#4 - setting up react to work with node js and express backend | oauth using mern stack in hindi", videid: "14QfrNV6x8I"},
                {tit: "#5 - creating basic UI and routing in react js | oauth using mern stack in hindi", videid: "kFf1sj9nZJ8"},
                {tit: "#6 - adding redux for detecting user is logged-in or not | oauth using mern stack in hindi", videid: "j53KMoQW8PE"},
                {tit: "#7 - changing navigation links on login and logout | oauth using mern stack in hindi", videid: "gzl7zuuSDm4"},
                {tit: "#8 - making profile page protected | oauth using mern stack in hindi", videid: "afR2purK2X0"},
                {tit: "#10 - implementing login/signup with facebook feature | oauth using mern stack in hindi", videid: "0194nsP3jAg"},
                {tit: "#11 - deploying mern stack project to heroku | oauth using mern stack in hindi", videid: "OZe_-_ob8kw"},
                {tit: "create box shadow generator tool using react js | react js project in hindi", videid: "kTl_ptBZrmo"},
                {tit: "Add dark Mode in react js Application or website | react js project in hindi", videid: "BXDLsW6k6f8"},
                {tit: "dark Mode part 2 in react js Application | prefers-color-scheme |react js project in hindi", videid: "LDqcQ381Udk"},
                {tit: "#1 - Youtube Video player and content list in react js | react js project in hindi", videid: "o8jEBYOu9dA"},
                {tit: "#2 - Youtube Video player and content list in react js | react js project in hindi", videid: "FOSqMousEYs"},
                {tit: "useSelector and useDispatch hooks in react redux | redux tutorial in hindi", videid: "gw5NTGpmZzg"}
        ],
        desc:"In ths course we will learn react js from zero to hero level. This course includes hooks, Redux, Context Api,integration with node js, authentication and lots of projects as well "
    },
    "React-Native":{
        playlist:[
            {tit: "#0 react native introduction and expo vs react native cli", videid: "10sNHsf2rVU"},
            {tit: "#1 project overview", videid: "G3yvOJfHtdM"},
            {tit: "#2 installing expo cli and running app on real device", videid: "OujKTHxYLcU"},
            {tit: "#3 understanding code", videid: "Xg6fm5guB8g"},
            {tit: "#4 home screen design", videid: "mEQjIuUhFtU"},
            {tit: "#5 home screen and flatlist", videid: "UBLl7ZvcVvA"},
            {tit: "#6 createEmploye screen", videid: "cnj-xWspRLk"},
            {tit: "#7 modal in react native", videid: "n_bVnIpEKFI"},
            {tit: "#8 profile screen part 1", videid: "xQnKTlk4HPY"},
            {tit: "#9 profile screen design part 2", videid: "9gIy9nGVacg"},
            {tit: "#10 opening email and Dialer app", videid: "oS7I7rVqeVc"},
            {tit: "#11 adding React Navigation v5", videid: "fm8jVuLCekE"},
            {tit: "#12 customising header", videid: "ntLhWIBN_8k"},
            {tit: "#13 making profile page dynamic", videid: "3jwl2Oamabk"},
            {tit: "#14 access camera image gallery", videid: "bSw_yjBtZWg"},
            {tit: "#15 uploading image to cloudinary", videid: "1SIYtfwAVTo"},
            {tit: "#16 setting up node js and mongodb atlas", videid: "-MUzt7qVmZQ"},
            {tit: "#17 schema and connecting to mongodb using mongoose", videid: "S7Qxt9ncCv4"},
            {tit: "#18 creating post route in node js", videid: "b_Uqcd5rznc"},
            {tit: "#19 creating update and delete route in node js", videid: "lHEkoN-ghm8"},
            {tit: "#20 Why ngrok", videid: "FeeyS_P0kD4"},
            {tit: "#21 posting data from react native to node js", videid: "JtxnhWF0ZlM"},
            {tit: "#22 fetching records in home Screen component", videid: "tcJD73ziFGI"},
            {tit: "#23 implementing pull to refresh in react native", videid: "PUoyq_vwIyI"},
            {tit: "#24 fire or delete employee", videid: "hg4kDsL6lzA"},
            {tit: "#25 edit or update employee details", videid: "I82jXjcKnHA"},
            {tit: "#26 adding keyboard avoiding view in create employee screen", videid: "S_5xMvaUUP0"},
            {tit: "#27 why redux is needed in react js and react native apps", videid: "TJ8w-4BbqLQ"},
            {tit: "#28 adding redux into our existing app", videid: "mtj7e4HvpFg"},
            {tit: "#29 Ditching Redux and adding context API our existing app", videid: "J0yhcWOIiRE"},
            {tit: "#0 Project Overview | Youtube clone", videid: "9BFwv-b4Ink"},
            {tit: "#1 Creating custom Header Component from scratch", videid: "WK17ZDi1yCs"},
            {tit: "#2 Creating  Home Screen Card component from scratch", videid: "uOeDfSiBc0w"},
            {tit: "#3 Search Screen Header", videid: "mTUuuEJXw28"},
            {tit: "#4 Creating search screen Cards", videid: "_1BlkPzqld8"},
            {tit: "#5  Making use of YouTube API", videid: "v9ew8ltRbWI"},
            {tit: "#6 Fixing video thumbnail issue", videid: "OvDDeWwuxRk"},
            {tit: "#7 Combining Navigators | Navigation structure theory", videid: "J2ad-S5ErV0"},
            {tit: "#8 Creating Screens and Combining Navigators", videid: "v7KgNLUHEIQ"},
            {tit: "#9 Adding tab icons and navigating between screens", videid: "hZ19QRNxQzw"},
            {tit: "#10 Adding Redux", videid: "sg2DsEECDtc"},
            {tit: "#11 Sharing data with Home screen using redux", videid: "iEUZrorguZU"},
            {tit: "#12 Working on Explore screen", videid: "U00K_GO5zf0"},
            {tit: "#13 WebView in React Native | working on video player screen", videid: "8zT6CYu0iYQ"},
            {tit: "#14 Adding Dark Theme or Dark Mode in React Native", videid: "iaZ1c9Q8JHo"},
            {tit: "#15 Toggle between Dark and Light Theme using Redux", videid: "kVx6_4WDubE"},
            {tit: "#16 Adding Icon and splash screen in React Native expo", videid: "TgFyksLD6zU"},
            {tit: "#17 Building Standalone Apps | generate apk using React Native expo", videid: "SzwTGDDXTAE"},
            {tit: "#0 Project Overview | Weather App", videid: "mwMhCos02Go"},
            {tit: "#1 Creating Project with React Native cli", videid: "x07Bhb7BxfU"},
            {tit: "#2 Creating Header for weather app h", videid: "Xo_7LyeDoFM"},
            {tit: "#3 Working on Search Screen", videid: "Vs2eS-XAnPI"},
            {tit: "#4 Fetching weather conditions from open weather map and displaying it", videid: "SCA8qAkKIjk"},
            {tit: "#5 Adding Tab Navigation in Weather app", videid: "IhRRRmaTt8A"},
            {tit: "#6 React Native Async Storage", videid: "gCb3JUsWw8Y"},
            {tit: "#7 Building app in React Native cli using Android studio", videid: "Dw29UFgt6Bk"},
            {tit: "Context API mastery", videid: "k_qRwc1anNs"},
            {tit: "#0  Animation Basics", videid: "uWpsHyKmEMo"},
            {tit: "#1 - Interpolate over value", videid: "7v4hs_XKsiY"},
            {tit: "#2 - Handling gestures", videid: "Y2HngTZ8fu8"},
            {tit: "#3 Hide Header on scroll up and visible on scroll down", videid: "mvxgWuxwnik"},
            {tit: "#4 - Instagram heart bounce Animation", videid: "mI-HX-SeBd8"},
            {tit: "#5 Translating and Rotating card with Layout Animation", videid: "gUHONhkah88"}
        ],
        desc:"In ths course we will be mastering React Native. This course includes accessing camera or image gallery, uploading images, pull to refresh, connecting react native with node js and mongodb,Youtube clone, Animations and lot more"
    },
    "MERN-stack-Instagram-clone":{
        playlist:[
            {tit: "#0 - Project Overview",videid:"lKoxKD2-rmE"},
            {tit: "#1 - hello world in node js",videid:"BpmURO-RIMk"},
            {tit: "2 what are middleware in node js", videid: "poMpGqu4ATs"},
            {tit: "#3 setting up mongodb", videid: "Tt_CS-iQBqQ"},
            {tit: "#4 Creating user schema", videid: "-UyRJK7rFzU"},
            {tit: "#5 getting data in request body", videid: "wrorU5Z4uiw"},
            {tit: "#6 saving or posting data in database", videid: "emWVi1YXtKs"},
            {tit: "#7 hashing password using bcrypt js", videid: "CiyywUfbJB0"},
            {tit: "#8 Creating signin route", videid: "-56IzWZufZU"},
            {tit: "#9 Sending token using JWT(jsonwebtoken)", videid: "UP6Dm--NOoM"},
            {tit: "#10 Creating middleware to verify token", videid: "Eon2pop2n_I"},
            {tit: "#11 Post schema and create post route", videid: "PRACd1gm8m0"},
            {tit: "#12 view all posts route", videid: "6Yvhac0TqZs"},
            {tit: "#13 all post created by me route", videid: "AAuvwBCK5pI"},
            {tit: "#14 creating react project & adding routing", videid: "KqR1t9OGYqg"},
            {tit: "#15 signin and signup page ui", videid: "O2dKM6Xkb9U"},
            {tit: "#16  profile page ui", videid: "rMPt7wCsOI4"},
            {tit: "#17 Home page ui", videid: "ZyxVDaj2hrc"},
            {tit: "#18 Create post page  UI", videid: "tjZaz8Dv0FU"},
            {tit: "#19 working on user signup by posting data", videid: "8LDgiePQPTU"},
            {tit: "#20  Working on User Signin", videid: "_ABAIvf7SGQ"},
            {tit: "#21 uploading image to cloudinary", videid: "uP568vOaBbQ"},
            {tit: "#23 saving token in local storage and solving errors", videid: "dXMAUfCQ75I"},
            {tit: "#23 Adding context", videid: "ryxYQlhB7Hg"},
            {tit: "#24 Changing Navigation links on Signin", videid: "IIB7_G5csY4"},
            {tit: "#25 Fetching post and showing that in home screen", videid: "PfH2Gx3nlHU"},
            {tit: "#26 Implementing logout", videid: "ijNflnE3vSY"},
            {tit: "#27 Getting user post and showing it on profile page", videid: "JGEcUDecrHY"},
            {tit: "#28 like and unlike post node js API", videid: "U7uyolAHLc4"},
            {tit: "#29 like unlike Implementation in react js", videid: "xVeE737QPy4"},
            {tit: "#30 Node js API for comment", videid: "_f67ge2vJ8Q"},
            {tit: "#31 Implementing comment in react js client", videid: "QRYcx9GDHfg"},
            {tit: "#32 Delete post", videid: "uc_4iMSCQ3E"},
            {tit: "#33 Node API to see the profile of other users", videid: "jo6w1Szdm1I"},
            {tit: "#34 implementing profile page of other users on client side", videid: "36M2Sj14kzc"},
            {tit: "#35 Node API for follow and unfollow", videid: "qDKIg7p8oRo"},
            {tit: "#36 Implementing follow user in react on client side", videid: "f9MWYmWDFsA"},
            {tit: "#37 Solving issues & unfollow user", videid: "KoFQEEextJc"},
            {tit: "#38 Updating follow unfollow of logged in user", videid: "W2OV4WAfCas"},
            {tit: "#39 Node js API to list the posts of my followings", videid: "zsCryJPEGIY"},
            {tit: "#40 See posts of my followings", videid: "oFhZ_UriS08"},
            {tit: "#41 Adding Profile pic of users", videid: "HnKLO8FcK2s"},
            {tit: "#42 Updating Profile pic", videid: "6EPYobfVxak"},
            {tit: "#43 Saving updated Profile pic in database", videid: "HzCRjcKwdrU"},
            {tit: "#44 Deploy to heroku", videid: "TTT_OtWwKLM"},
            {tit: "#45 Send email in node js using nodemailer & sendgrid", videid: "-3fBUyO9nbw"},
            {tit: "#46 Sending reset password link in email", videid: "NOuiitBbAcU"},
            {tit: "#47 Create New password and save it in db", videid: "MfqyFcP6hTY"},
            {tit: "#48 Showing latest post on top", videid: "Tx_4KXbKCTQ"},
            {tit: "#49 Search users feature client side", videid: "v-NnN01wwc8"},
            {tit: "#50 Search users functionality on backend", videid: "e4VVDYhLRkw"},
            {tit: "#51 Deploying changes to heroku", videid: "srXTA0LvSks"}
        ],
        desc:"In this course we will be making Full-fledged instragram clone"
    },
    "Data-Science":{
        playlist:[
                {tit: "#0 - What is Data Science | Introduction to Data science and Machine learning in Hindi", videid: "-yN78ufLJk8"},
                {tit: "#1 Jupyter Notebook crash course | Data science tutorial in Hindi", videid: "sbQx0vpQxEY"},
                {tit: "#2 NumPy in one Video | data science tutorial in Hindi", videid: "qg2N1NBuopg"},
                {tit: "#3.1 Pandas Introduction and series in pandas | Data science tutorial in Hindi", videid: "ezaOd_emEtI"},
                {tit: "#3.2 Pandas DataFrame basics | Data science tutorial in Hindi", videid: "LvOkRJAoD8w"},
                {tit: "#3.3 More on Pandas DataFrame in Hindi | Data science tutorial in Hindi", videid: "YEQ7OqK2yZs"},
                {tit: "#3.4 Read CSV File using pandas | Mini Project | Data science tutorial in Hindi", videid: "nmPvu0atK2I"},
                {tit: "#4.1 Matplotlib for Data Visualizations | Data Science tutorial in Hindi", videid: "fgpNfimEVAc"},
                {tit: "#4.2 Matplotlib for data visualization part 2 | Data science tutorial in Hindi", videid: "Zq1-RG-IZ7g"},
                {tit: "#4.3 Visualize COVID 19 India data from Wikipedia using Matplotlib | Data science tutorial in Hindi", videid: "xrDERDjKYGE"},
                {tit: "#5 Linear Regression Algorithm in Machine Learning using scikit learn in Hindi", videid: "g2FlDlepTQs"},
                {tit: "#6 Split data into train and test subsets using scikit learn | Data science tutorial in Hindi", videid: "T_3FE9PdkeE"},
                {tit: "#7 Multiple Linear Regression using scikit learn | Data Science and Machine learning in Hindi", videid: "la1OkcPw5I8"},
                {tit: "#8 Logistic Regression Algorithm using scikit learn | Data science and Machine learning in Hindi", videid: "HWKdBVDxXBI"},
                {tit: "#9 Multinomial Logistic Regression using scikit learn | Data science and machine learning in Hindi", videid: "sALyqtP1n3g"},
                {tit: "#10 Decision Tree Algorithm using scikit learn | Data science and Machine learning in Hindi", videid: "TwZHd5Fp0Iw"},
                {tit: "#11 Random Forest Algorithm using scikit learn | Data science and Machine learning in Hindi", videid: "JdE_6u-7ntA"},
                {tit: "#0 Flask + ML project Overview | Flask tutorial in Hindi", videid: "Pv-Qx23h4oE"},
                {tit: "#1 Hello world in Flask | Flask tutorial in Hindi", videid: "VO79ipc3pik"},
                {tit: "#2 Url parameters & Jinja2 Template engine | Flask tutorial in Hindi", videid: "xp-GOsqbUN0"},
                {tit: "#3 Template Inheritance in flask | Flask tutorial in Hindi", videid: "bQ08RoVjiss"},
                {tit: "#4 Getting Form data on server | Flask tutorial in Hindi", videid: "NbZZOodCupA"},
                {tit: "#5 Creating ML model to predict diabetes using scikit learn | Flask tutorial in Hindi", videid: "PdcfvS-JvNU"},
                {tit: "#6 Using ML model in Flask | Flask tutorial in Hindi", videid: "eIPRS_WwBw0"},
                {tit: "#7 Deploy Flask + ML web application project on heroku | Flask tutorial in HIndi", videid: "kB_XPA88I68"}
        ],
        desc:`This this playlist we will be learning Data science and machine learning in Hindi.
        It has following topics covered 
        1) Jupyter Notebook 
        2) Numpy 
        3) Pandas 
        4) Matplotlib for data visualization 
        6) Machine Learning using sklearn 
        7)  Deploy ML model using flask`
    },
    "Django-course":{
        playlist:[
            {tit: "#0 Introduction to django | project overview ", videid: "K_Lz_7CMb7Q"},
            {tit: "#1 Virtual environment and django setup", videid: "F-6KnE6qArE"},
            {tit: "#2 Views and URLS in Django", videid: "0QS9PYfJqks"},
            {tit: "#3 Templates | Django template language basics", videid: "bEeCSgggMZU"},
            {tit: "#4 Template inheritance In Django and adding Navigation Bar", videid: "RJX6_qLQxZg"},
            {tit: "#5 Models and Migrations in Django", videid: "lT_I3YMCviI"},
            {tit: "#6 Creating Blog using ADMIN Panel", videid: "759NZNvtfWc"},
            {tit: "#7 handling media upload", videid: "8nmnhE0kilY"},
            {tit: "#8 Displaying objects (blogs)", videid: "szaT0DhzZVc"},
            {tit: "#9 Template filters in django", videid: "JAz7LJbbVZk"},
            {tit: "#10 Handling static files in django", videid: "O_sAMF5t1c4"},
            {tit: "#11 URL parameters and individual blog page", videid: "MGwkUEDNLeQ"},
            {tit: "#12 App URLS in Django", videid: "xmm78Ara414"},
            {tit: "#13 Show latest post on top | Order by", videid: "WWBMdYm7OtU"},
            {tit: "#14 Creating new project", videid: "PfUCDxQmlM0"},
            {tit: "#15 Signup Form", videid: "MiuSHpoIw1s"},
            {tit: "#16 Signup Route handler", videid: "cf8131lR1Vo"},
            {tit: "#17 Access user details in home page", videid: "GDaIX5A3DfE"},
            {tit: "#18 Adding Navigation Bar & logout", videid: "pvlq22mATKY"},
            {tit: "#19 Login page & change Nav links", videid: "dd0VFHkFXp8"},
            {tit: "#20 Creating todo Model", videid: "V13fnOMxzrc"},
            {tit: "#21 Creating Form & todos", videid: "x6ttyDZwmZo"},
            {tit: "#22 Displaying Todos", videid: "Z9XfEygVT48"},
            {tit: "#23 Delete Todos", videid: "DARWil_tRzs"},
            {tit: "#24 Styling Applicaion", videid: "9xdiM-56GIs"},
            {tit: "#25 Deploy to Heroku", videid: "pTCDRyj_sks"}

        ],
        desc:'In this Course we will be learning about Django 3 and we will build two projects as well'
       
    },
    "Ecommerce-Nextjs":{
        playlist:[
               {tit: "#0 Introduction to Next js |  React Server Side Rendering using Next js | Next js tutorial in Hindi", videid: "n47Y5-hSFSs"},
                {tit: "#1 - Project Overview | E commerce using Next js | Next js tutorial in Hindi", videid: "VIYdJTiIm1o"},
                {tit: "#1 Next js basics | React server Side Rendering | E commerce using Next js", videid: "afXzuf2C9Lw"},
                {tit: "#2 Adding Layout & Navigation Bar | React Server side Rendering | E commerce tutorial using Next js", videid: "t0wZYzx0qdY"},
                {tit: "#3 Connect to MongoDB, Creating Product model & API | React SSR | Next js tutorial in Hindi", videid: "Xazy4QaveJI"},
                {tit: "#4 Fetch Products and display on home page | React Server Side rendering | Next js tutorial in Hindi", videid: "shU59Gkq9x0"},
                {tit: "#5 Product detail page | React Server side Rendering | Next js tutorial in Hindi", videid: "nR8kV8aTD_0"},
                {tit: "#6 Individual product page and delete product | React Server Side Rendering | Next js in Hindi", videid: "_GWpB4ita4E"},
                {tit: "#7 Create Product page UI | React server side Rendering | E commerce using Next js in Hindi", videid: "k4-yy8mcW20"},
                {tit: "#8 Product Image upload and API for create product | React SSR | E commerce using Next js in Hindi", videid: "Y7Ff6yPOu88"},
              {tit: "#9 Signup and Login UI | React server side Rendering | E commerce using Next js tutorial in Hindi", videid: "lmP-0cLeuEE"},
              {tit: "#10 Adding Signup functionality | React Server Side Rendering | E commerce using Next js in Hindi", videid: "8Dn_iC87OAA"},
              {tit: "#11 Implementing login functionality | React Server Side Rendering | E commerce using Next js Hindi", videid: "jS4HgAbW03s"},
              {tit: "#12 Logout and hiding protected routes | React Server Side Rendering | Next js tutorial in Hindi", videid: "qr-P073zUxY"},
              {tit: "#13 Cart model & fetch user cart | React Server Side Rendering | Next js tutorial in Hindi", videid: "UIOFw5DmVH4"},
              {tit: "#14 Authentication middleware using HOC & adding product to cart | React SSR | Next js in Hindi", videid: "Cm4M5G4qyA4"},
              {tit: "#15 Showing product in cart and remove product from cart | React SSR | Next js tutorial in Hindi", videid: "sxNw0SVB0ZE"},
              {tit: "#16 Cart Checkout using stripe API | React Server Side Rendering | Next js tutorial in Hindi", videid: "-QxPux2D444"},
              {tit: "#17 Order model and empty cart after checkout | React server side Rendering | Next js in Hindi", videid: "8jlnL3IB2_M"},
              {tit: "#18 Listing Order history in account page | React server side rendering | E commerce using next js", videid: "yFGqe9-gQts"},
              {tit: "#19 Fetch all users roles in account for root user | React server side rendering | Next js tutorial", videid: "rhPNXcJ35X4"},
              {tit: "#20 Change or toggle user role | React server side Rendering | E commerce tutorial using Next js", videid: "jfN-howX7Ao"},
              {tit: "#21 Deploy Next js Application to vercel | react server side rendering | Next js in Hindi", videid: "3NNgUBWMtO0"}
       
        ],
        desc:'In this Course we will be learning React Server side Rendering using Next js & we will build a Ecommerce website as well'
       
    },

}