import React from 'react';
import {Link} from 'react-router-dom';

class Header extends React.Component{
//document.querySelector(".sidenav").M_Sidenav.close()
constructor(props){
  super(props)
  this.mynav  = React.createRef() 
}
closeNav(){
 this.mynav.current.M_Sidenav.close()
}
render(){
  return (
    <div className="navbar-fixed" style={{zIndex: 999}}>
      <nav className="#6200ea deep-purple accent-4">
            <div className="nav-wrapper left-align">
              <Link className="mylogo" to="/" style={{fontSize: "25px"}}>
    
              Coders Never Quit
              
              </Link>
              <a  data-target="mobile-demo" style={{margin:"0px",padding:"0px 3%"}} className="sidenav-trigger"><i className="fa fa-bars" style={{fontSize:"20px",color:"white",padding:"0px"}}></i></a>
              <ul id="nav-mobile" className="right hide-on-med-and-down">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">About us</Link></li>
                <li><Link to="/courses">Courses</Link></li>
                <li><a href="https://www.youtube.com/c/CODERSNEVERQUIT">YOUTUBE CHANNEL</a></li>
              </ul>
            </div>
           
        </nav>
        <ul className="sidenav #212121 grey darken-4" id="mobile-demo" ref={this.mynav}>
                <li><Link to="/" style={{color:"white"}} onClick={()=>this.closeNav()}>Home</Link></li>
                <li><Link to="/about" style={{color:"white"}} onClick={()=>this.closeNav()}>About us</Link></li>
                <li><Link to="/courses" style={{color:"white"}} onClick={()=>this.closeNav()}>Courses</Link></li>
                <li><a href="https://www.youtube.com/c/CODERSNEVERQUIT" style={{color:"white"}}>YOUTUBE CHANNEL</a></li>
        </ul>

      
    </div>
        
  )
}
 
}

export default Header;