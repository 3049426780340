import React from 'react';
import Typed from 'react-typed';
import BGill from '../images/newde.png'


const rendercircles = ()=>{
    return (
        <>
         <div className="circle0"></div>
         <div className="circle2"></div>
         <div className="circle3"></div>
         
        </>
    )
}
const Mybackground =()=>{
  return (
      <div className="bigbackground">
      <div className="row flex-s">

      <div className="col s12 m6 box-a">
            <div className="herotext" >
                    
               <span>
                   <span style={{color:"#ff04ab"}}>const</span> free = <span style={{color:"#ff04ab"}}>new</span> Programming()
               </span> 
               <br />
                {/* <Typed 
                        strings={['<span style="color:red;font-weight=bold">const</span> free = <span style="color:red;font-weight=bold">new</span> Programming()']} 
                        typeSpeed={40} 
                        showCursor={false}
                    /> */}
                
                <Typed 
                        strings={['<span style="color:#ff04ab;font-weight=bold">free</span>.learn("javascript")','<span style="color:#ff04ab;font-weight=bold">free</span>.learn("Node.js")','<span style="color:#ff04ab;font-weight=bold">free</span>.learn("React Native")','<span style="color:#ff04ab;font-weight=bold">free</span>.learn("React.js and redux")','<span style="color:#ff04ab;font-weight=bold">free</span>.learn("Python")']} 
                        typeSpeed={40} 
                        // startDelay={2000}
                        loop
                    />
                
                
                </div>
                <p className="herotext">
                    We are on the mission to educate everyone for free. <br/>
                    Programming is made fun, exciting and super easy with us.
                </p> 
               
                {rendercircles()}
                
                     
     </div>
          <div className="col s12 m6 newdesignbg">
               <img src={BGill} alt={BGill} className="responsive-img"/>
          </div>
             
      
      </div>
       
      </div>
        
  )
}

export default Mybackground;