import React from 'react';


// <polygon className="svg--sm" fill="#d32f2f" points="0,0 30,100 65,21 90,100 100,75 100,100 0,100"/>
const Wave = ()=>{
  return (

    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none"  className="mysvg"
      viewBox="1.225 255.147 1021.508 209.815" width="1021.508pt" height="209.815pt">
      <path  d=" M 1.225 342.442 Q 169.69 397.576 315.182 403.702 C 460.674 409.828 508.15 304.155 705.713 336.316 Q 903.276 368.478 1022.733 255.147 L 1022.733 464.962 L 1.225 464.962 L 1.225 342.442 Z " fill="rgb(98,0,234)"/>
    </svg>

  )
}

export default Wave;