import React from 'react';
import './App.css';
import Header from './components/nav';

import Social from './components/Social';
import {BrowserRouter,Route} from 'react-router-dom'
import Home from './myroutes/Home';
import About from './myroutes/About';
import Courses from './myroutes/Courses';
import CourseStructure from './myroutes/CourseStructure';

//api = https://www.googleapis.com/youtube/v3/search?key=keyU&channelId=UCM-4HE0R3iLxKB7qaq1bskw&part=snippet,id&order=date&maxResult=10
function App() {
  return (
     <BrowserRouter>
       <Header />
       <Route exact path="/" component={Home} />
       <Route path="/about" component={About} />
       <Route exact path="/courses" component={Courses} />
       <Route path="/courses/:name" component={CourseStructure} />
       <Social />
    
    </BrowserRouter> 
    
  );
}

export default App;
