import React from 'react';
import {Bounce} from 'react-reveal';
class Youtube extends React.Component{
    state={
       data:[
           {id:"MXZvQuG5mQc",
           image:"https://i.ytimg.com/vi/MXZvQuG5mQc/hqdefault.jpg",
           title:"React js basics | jsx | props | state | component | React js tutorials in hindi",
           desc:"In this video we will learn basics for react js in one video"
          },
          {id:"3c7tfa1cnuU",image:"https://i.ytimg.com/vi/3c7tfa1cnuU/hqdefault.jpg",title:"WhatsApp UI Clone in react native | react native with expo tutorials in hindi",
          desc:"in this video we will learn how to make whatsapp ui using react native .react native tutorials with expo in hindi"},
          {id:"ahZPLgj1tnY",image:"https://i.ytimg.com/vi/ahZPLgj1tnY/hqdefault.jpg",title:"Introduction to redux | why redux | what is redux | react and redux tutorial in hindi",
          desc:"In this video we will see why redux is used in react application.what exactly redux is ?its not mandatory to use redux in our react js application but we use it in bigger project to make our life easier"},
          {id:"8y5615TkjA8",image:"https://i.ytimg.com/vi/8y5615TkjA8/hqdefault.jpg",title:"Gain instagram followers",desc:"in this video we will automate instagram using js"}]
    }
    componentDidMount(){

       fetch(`https://www.googleapis.com/youtube/v3/search?key=${process.env.REACT_APP_YOUTUBE_KEY}&channelId=UCM-4HE0R3iLxKB7qaq1bskw&part=snippet,id&order=date&maxResults=10`)
       .then(res2=>res2.json())
       .then(res2=>{
           
          if(res2.items){
             const newData=  res2.items.map((item)=>{
            return { 
                       id:item.id.videoId,
                       title:item.snippet.title,
                       desc:item.snippet.description,
                       image:item.snippet.thumbnails.high.url }
                     
           })
           
           this.setState({
               data:newData
           }) 

          }
        
       })
    }
    render(){
        const myCards = this.state.data.map(item=>{
            return (
               <Bounce  bottom key={item.id}>
                <div className="hoverable">
                  <div className="card" >
                    <div className="row">
                    <div className="col l4 m4" style={{padding:"3px 3px"}}>
                        <div className="card-image">
                            <img src={item.image} className="responsive-img" alt=""/>
                        </div>
                        </div>   
                    <div className="col l8 m8" style={{padding:"20px"}}>
                               <h5>{item.title}</h5>
                                <div>
                                <p style={{fontSize:"16px"}}>{item.desc}</p>
                                </div>
                                <div>
                                <a style={{color:"#d32f2f",fontWeight:"bold"}} href={`https://www.youtube.com/watch?v=${item.id}`} >watch it</a>
                                </div>
                            </div>
                
                
                  </div>
                  {/* <div style={{display:"flex"}}>
                       <div className="card-image" style={{flex:"3"}}>
                            <img src={item.image} className="responsive-img" alt=""/>
                        </div>
                        <div style={{flex:"6"}}>
                                <h5>{item.title}</h5>
                                <div>
                                <p style={{fontSize:"16px"}}>{item.desc}</p>
                                </div>
                                <div>
                                <a style={{color:"#d32f2f",fontWeight:"bold"}} href={`https://www.youtube.com/watch?v=${item.id}`} >watch it</a>
                                </div>
                        </div>
                  </div> */}
                  </div>  
                    
                  </div>
                  </Bounce>  
                 
            )
        })
        return (
        <div className="container" style={{paddingBottom:"80px"}}>
            <div style={{marginTop:"0px",paddingTop:"20px"}}>
                <h2 className="header white-text" style={{ margin: 0,marginBottom:"35px",fontSize: "calc(1em + 2vw)"}}>Our recent videos</h2>
            </div>
             
              {myCards}
              <div style={{textAlign:"center"}}>
                <a className="waves-effect waves-light btn #6200ea deep-purple accent-4" href="https://www.youtube.com/c/CODERSNEVERQUIT" style={{fontSize:"24px",marginTop:"28px"}}>All Tutorials ></a> 
              </div>
              
        </div>
  );  
    }

}

export default Youtube;