import React from 'react';


const Social = ()=>{

  return (
    <div className="#6200ea deep-purple accent-4" style={{display: "inline-block",width: "100%"}}>
      <div>
          <h2 className="white-text" style={{fontSize: "calc(1em + 2vw)",marginLeft:"40px"}}>Find us on - </h2>
          {/* <div className="row">
                <div className="col s2"><a href="https://www.facebook.com/mukesh.phulwani.5" className="fa fa-facebook hoverable" /></div>
                <div className="col s2"> <a href="https://www.instagram.com/mukeshphulwani66/" className="fa fa-instagram hoverable" /></div>
                <div className="col s2"> <a href="https://www.youtube.com/channel/UCM-4HE0R3iLxKB7qaq1bskw" className="fa fa-youtube hoverable"></a></div>
                <div className="col s2"><a href="https://www.linkedin.com/in/mukesh-phulwani-681450152/" className="fa fa-linkedin hoverable"></a></div>
                <div className="col s2"><a href="https://github.com/mukeshphulwani66" className="fa fa-github hoverable"></a></div>
                <div className="col s2"><a href="https://play.google.com/store/apps/dev?id=4933067143148015449&hl=en_IN" className="fa fa-play hoverable"></a></div>
              
        </div>  */}
          <div style={{display:"flex",justifyContent:"space-evenly"}}>
                <a href="https://www.facebook.com/mukesh.phulwani.5" className="fa fa-facebook hoverable" />
                <a href="https://www.instagram.com/mukeshphulwani66/" className="fa fa-instagram hoverable" />
                <a href="https://www.youtube.com/channel/UCM-4HE0R3iLxKB7qaq1bskw" className="fa fa-youtube hoverable"></a>
                <a href="https://www.linkedin.com/in/mukesh-phulwani-681450152/" className="fa fa-linkedin hoverable"></a>
                <a href="https://github.com/mukeshphulwani66" className="fa fa-github hoverable"></a>
                <a href="https://play.google.com/store/apps/dev?id=4933067143148015449&hl=en_IN" className="fa fa-play hoverable"></a>
              
        </div> 
        <p className="center-align white-text">Copyright &copy; 2020 codersneverquit.in</p>
      </div>
      </div>
   
        
  )
}

export default Social;